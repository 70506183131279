.App {
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.page-header {
  background: #F5F5F5;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inner-page-header {
  display: flex;
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 75%;
  justify-content: space-between;
  align-items: center;
}

/* .photo-holder{
  height: 150px;
} */

.name  {
  font-weight: bold;
  font-size: 60px;
}

.ui.button.tertiary {
  border-width: 0;
}

.content-pane {
  width: 40%;
  padding: 1rem 0;
}

.text {
  font-size: 1.5rem;
}

.contact {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}